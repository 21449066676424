
.ban-list {
    &, & * {
        box-sizing: border-box;
    }
    .forms-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-and-del {
            padding-left: 10px;
        }
    }
    .words-list {
        margin-top: 20px;
        height: calc(100% - 62px);
    }
}
